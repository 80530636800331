import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a01f1c88"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "highscoreContainer"
}
const _hoisted_2 = { class: "highscoreModuleSelection" }
const _hoisted_3 = { class: "el-dropdown-link" }
const _hoisted_4 = {
  key: 1,
  class: "oneLineText highscoreModuleName"
}
const _hoisted_5 = {
  key: 2,
  class: "oneLineText highscoreModuleName"
}
const _hoisted_6 = { class: "TableSelectionHeadline" }
const _hoisted_7 = { class: "highscoreModuleSelection" }
const _hoisted_8 = { class: "el-dropdown-link" }
const _hoisted_9 = { class: "oneLineText highscoreModuleName" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_Highscore = _resolveComponent("Highscore")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_ctx.axes && _ctx.chartData.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableArray, (axis, index) => {
          return (_openBlock(), _createBlock(_component_el_card, {
            class: _normalizeClass(["highScoreSelectionContainer", {
        addOn__boarder: !(_ctx.tableArray[index] && _ctx.chartData.length > 0),
      }]),
            key: 'highscoreSelectionContainer' + axis.taskId,
            shadow: "never",
            "body-style": "text-align: center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.axes.length > 1)
                  ? (_openBlock(), _createBlock(_component_el_dropdown, {
                      key: 0,
                      onCommand: ($event: any) => (_ctx.tableArray.splice(index, 1, $event)),
                      trigger: "click",
                      placement: "bottom"
                    }, {
                      dropdown: _withCtx(() => [
                        _createVNode(_component_el_dropdown_menu, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.axes, (ax, axIndex) => {
                              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                key: ax ? ax.taskId + 'ax' : axIndex,
                                command: ax ? ax : null
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(ax ? ax.taskData.taskName : 'N/A'), 1)
                                ]),
                                _: 2
                              }, 1032, ["command"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          (_ctx.tableArray[index] && _ctx.chartData.length > 0)
                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                key: 0,
                                class: "highscoreModuleIcon",
                                icon: _ctx.getIconOfAxis(_ctx.tableArray[index] || axis),
                                style: _normalizeStyle({
                color: _ctx.getColorOfAxis(_ctx.tableArray[index] || axis),
              })
                              }, null, 8, ["icon", "style"]))
                            : _createCommentVNode("", true),
                          (_ctx.tableArray[index] && _ctx.chartData.length > 0)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                                _createTextVNode(_toDisplayString(_ctx.tableArray[index]?.taskData.taskName) + " ", 1),
                                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angle-down'] })
                              ]))
                            : (_openBlock(), _createElementBlock("p", _hoisted_5, [
                                _createTextVNode(" select task "),
                                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angle-down'] })
                              ]))
                        ])
                      ]),
                      _: 2
                    }, 1032, ["onCommand"]))
                  : _createCommentVNode("", true),
                (_ctx.tableArray[index] && _ctx.chartData.length > 0)
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 1,
                      icon: ['fas', 'trash'],
                      class: "trashButton",
                      onClick: ($event: any) => (_ctx.removeFromTableArray(index))
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.tableArray[index] && _ctx.chartData.length > 0)
                ? (_openBlock(), _createBlock(_component_Highscore, {
                    key: 0,
                    class: "highscore",
                    "module-id": _ctx.tableArray[index]!.taskId,
                    "table-data": _ctx.filterParticipantData(JSON.parse(JSON.stringify(_ctx.chartData)), _ctx.tableArray[index]!.taskId),
                    "selected-participant-id": _ctx.selectedParticipantId,
                    "translation-path": _ctx.getTranslationPath(_ctx.tableArray[index]),
                    onParticipantSelected: _ctx.participantSelectionChanged
                  }, null, 8, ["module-id", "table-data", "selected-participant-id", "translation-path", "onParticipantSelected"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["class"]))
        }), 128)),
        _createVNode(_component_el_card, {
          class: "highScoreSelectionContainer addOn__boarder is-align-self-center",
          shadow: "never",
          "body-style": "text-align: center"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('moderator.organism.analytics.tables.table')), 1),
            _createElementVNode("div", _hoisted_7, [
              (_ctx.axes.length > 1)
                ? (_openBlock(), _createBlock(_component_el_dropdown, {
                    key: 0,
                    onCommand: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addToTableArray($event))),
                    trigger: "click",
                    placement: "bottom"
                  }, {
                    dropdown: _withCtx(() => [
                      _createVNode(_component_el_dropdown_menu, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.axes, (ax, axIndex) => {
                            return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                              key: ax ? ax.taskId + 'ax' : axIndex,
                              command: ax ? ax : null
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(ax ? ax.taskData.taskName : 'N/A'), 1)
                              ]),
                              _: 2
                            }, 1032, ["command"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("p", _hoisted_9, [
                          _createTextVNode(_toDisplayString(_ctx.$t('moderator.organism.analytics.tables.selectTask')) + " ", 1),
                          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angle-down'] })
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}