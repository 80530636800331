import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26f94191"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["element-loading-background", "element-loading-text"]
const _hoisted_2 = ["element-loading-background"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_parallel_coordinates = _resolveComponent("parallel-coordinates")!
  const _component_Tables = _resolveComponent("Tables")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    id: "analytics",
    style: { marginTop: '3rem' },
    "element-loading-background": 'var(--color-background)',
    "element-loading-text": _ctx.$t('moderator.organism.analytics.loading')
  }, [
    _createElementVNode("div", {
      class: "AnalyticsParallelCoordinates",
      style: _normalizeStyle({
        opacity: _ctx.loadingSteps ? 0 : 1,
      })
    }, [
      (_ctx.axes.length > 0 && _ctx.dataEntries.length > 0 && !_ctx.loadingSteps)
        ? (_openBlock(), _createBlock(_component_parallel_coordinates, {
            key: 0,
            "chart-axes": 
          JSON.parse(JSON.stringify(_ctx.axes.filter((axis) => axis.available)))
        ,
            "participant-data": JSON.parse(JSON.stringify(_ctx.dataEntries)),
            steps: JSON.parse(JSON.stringify(_ctx.steps)),
            "selected-participant-id": _ctx.selectedParticipantId
          }, null, 8, ["chart-axes", "participant-data", "steps", "selected-participant-id"]))
        : _createCommentVNode("", true)
    ], 4),
    _createElementVNode("div", {
      class: "AnalyticsTables",
      "element-loading-background": 'var(--color-background)',
      style: _normalizeStyle({
        opacity: _ctx.loadingSteps ? 0 : 1,
      })
    }, [
      (_ctx.axes.length > 0 && _ctx.dataEntries.length > 0 && !_ctx.loadingSteps)
        ? (_openBlock(), _createBlock(_component_Tables, {
            key: 0,
            "participant-data": JSON.parse(JSON.stringify(_ctx.dataEntries)),
            axes: 
          JSON.parse(JSON.stringify(_ctx.axes.filter((axis) => axis.available)))
        ,
            onParticipantSelected: _ctx.participantSelectionChanged,
            style: _normalizeStyle({
          opacity: _ctx.loadingSteps ? 0 : 1,
        })
          }, null, 8, ["participant-data", "axes", "onParticipantSelected", "style"]))
        : _createCommentVNode("", true)
    ], 12, _hoisted_2)
  ], 8, _hoisted_1)), [
    [_directive_loading, _ctx.loadingSteps]
  ])
}