import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a64e336"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "radar-chart" }
const _hoisted_2 = ["width", "height"]
const _hoisted_3 = ["points"]
const _hoisted_4 = ["x2", "y2"]
const _hoisted_5 = ["points", "fill", "stroke"]
const _hoisted_6 = ["x", "y"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      width: _ctx.size,
      height: _ctx.size,
      viewBox: "-15 -15 130 130"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gridLevels, (level, index) => {
        return (_openBlock(), _createElementBlock("polygon", {
          key: 'grid-' + index,
          points: _ctx.getPolygonPoints(level),
          fill: "none",
          stroke: "#ccc",
          "stroke-width": "0.2"
        }, null, 8, _hoisted_3))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (label, index) => {
        return (_openBlock(), _createElementBlock("line", {
          key: 'axis-' + index,
          x1: "50",
          y1: "50",
          x2: _ctx.getAxisEnd(index).x,
          y2: _ctx.getAxisEnd(index).y,
          stroke: "#666",
          "stroke-width": "0.2"
        }, null, 8, _hoisted_4))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datasets, (dataset, datasetIndex) => {
        return (_openBlock(), _createElementBlock("polygon", {
          key: 'dataset-' + datasetIndex,
          points: _ctx.getDataPoints(dataset.data),
          fill: dataset.color,
          "fill-opacity": 0.4,
          stroke: dataset.color,
          "stroke-width": "0.5"
        }, null, 8, _hoisted_5))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (label, index) => {
        return (_openBlock(), _createElementBlock("text", {
          key: 'label-' + index,
          x: _ctx.getAxisEnd(index, 55).x,
          y: _ctx.getAxisEnd(index, 55).y,
          "text-anchor": "middle",
          "font-size": "5",
          fill: "#333"
        }, _toDisplayString(label), 9, _hoisted_6))
      }), 128))
    ], 8, _hoisted_2))
  ]))
}