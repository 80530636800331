import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20454bd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "gallery",
  class: "gallery"
}
const _hoisted_2 = { class: "gallery-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IdeaCard = _resolveComponent("IdeaCard")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.ideas.length > 0)
      ? (_openBlock(), _createBlock(_component_el_carousel, {
          key: 0,
          height: `${this.contentHeight}px`,
          type: "card",
          arrow: "always",
          "initial-index": 0,
          interval: _ctx.paused ? 0 : 7000 / _ctx.timeModifier,
          onChange: _ctx.galleryIndexChanged,
          trigger: "click"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ideas, (idea, index) => {
              return (_openBlock(), _createBlock(_component_el_carousel_item, {
                key: index,
                name: index.toString()
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_IdeaCard, {
                      idea: idea,
                      "is-editable": false,
                      class: "public-idea",
                      portrait: _ctx.portrait
                    }, null, 8, ["idea", "portrait"])
                  ])
                ]),
                _: 2
              }, 1032, ["name"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["height", "interval", "onChange"]))
      : _createCommentVNode("", true)
  ], 512))
}